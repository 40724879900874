import React from "react";
import * as styles from "./Modal.module.scss";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import { CursorContext } from "../context/CursorContext";
import IconBackPage from "./icons/IconBackPage";
import { isMobile } from "react-device-detect";

export default function Modal({
  children,
  one,
  scroll,
  invertButton,
  navigateRoute,
  backButton,
}) {
  const [, setCursor] = React.useContext(CursorContext);

  function navBack(e) {
    e.stopPropagation();
    navBackNoProp();
  }

  function navBackNoProp(e) {
    toggleCursor(false);
    navigate("/");
  }

  const toggleCursor = React.useCallback((enabled) => {
    setCursor(() => ({ close: enabled }));
  });

  return (
    <div className={styles.ModalContainer} onClick={navBackNoProp}>
      <div className={styles.Backplate} onClick={navBack}></div>
      <div
        className={`${one ? styles.ModalOne : styles.Modal} ${
          scroll ? styles.ModalScroll : null
        }`}
        onMouseEnter={() => toggleCursor(false)}
        onMouseLeave={() => toggleCursor(true)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        id="modal"
      >
        {children}
        <Link
          to={navigateRoute ? navigateRoute : "/"}
          className={
            invertButton ? styles.ModalExitButtonInvert : styles.ModalExitButton
          }
        >
          {backButton || isMobile ? <IconBackPage /> : null}
        </Link>
      </div>
    </div>
  );
}
