import React from "react";
import IconBase from "./IconBase";
import icon from "./back_page.svg";

export default function IconBackPage({ width, height }) {
  return (
    <IconBase
      alt="zur vorherigen Seite"
      img={icon}
      width={width}
      height={height}
    />
  );
}
