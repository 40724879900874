import * as React from "react";
import Modal from "../components/Modal";
import * as styles from "./GalleryModal.module.scss";
import { CursorContext } from "../context/CursorContext";

const GalleryModal = ({ children, backButton, navigateRoute, project }) => {
  const sliderRef = React.useRef(null);
  const rightRef = React.useRef(null);
  const leftRef = React.useRef(null);

  const [, setCursor] = React.useContext(CursorContext);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [childstate, setChildState] = React.useState(children);
  const [currentScrolling, setCurrentScrolling] = React.useState(0);
  const [cursorPos, setCursorPos] = React.useState(0);

  const toggleCursorBack = React.useCallback((enabled) => {
    setCursor(() => ({ backward: enabled }));
  });

  const toggleCursorForward = React.useCallback((enabled) => {
    setCursor(() => ({ forward: enabled }));
  });

  let cursor = {
    x: 0,
    y: 0,
  };

  React.useEffect(() => {
    document.addEventListener(
      "mousemove",
      (e) => {
        setCursorPos(e.clientX);
      },
      false
    );
  }, []);

  React.useEffect(() => {
    clearTimeout(timeoutId);

    if (currentScrolling === -1) {
      pushLeft();
    } else if (currentScrolling === 1) {
      pushRight();
    } else {
      clearTimeout(timeoutId);
    }
  }, [currentScrolling, cursorPos]);

  function dragStart(e) {
    e = e || window.event;
    e.preventDefault();
    cursor = {
      x: e.clientX,
      y: e.clientY,
    };
    document.onmouseup = dragEnd;
    document.onmousemove = drag;
    watchAppend();
  }
  function drag(e) {
    e = e || window.event;

    sliderRef.current.scrollLeft -= e.clientX - cursor.x;
    cursor.x = e.clientX;
    watchAppend();
  }
  function dragEnd(e) {
    document.onmouseup = null;
    document.onmousemove = null;
    watchAppend();
  }
  function pushRight(e) {
    var ref = rightRef.current.getBoundingClientRect();
    var x = cursorPos - ref.left; //x position within the element.
    x = Math.min(Math.max(x, 0), 200);

    sliderRef.current.scrollLeft += x / 20;
    watchAppend();

    if (currentScrolling) {
      let id = setTimeout(pushRight, 5);
      setTimeoutId(id);
    }
  }
  function pushLeft(e) {
    var ref = leftRef.current.getBoundingClientRect();
    var x = cursorPos - ref.left; //x position within the element.
    x = leftRef.current.clientWidth - Math.min(Math.max(x, 0), 200);

    sliderRef.current.scrollLeft -= x / 20;

    if (currentScrolling) {
      let id = setTimeout(pushLeft, 5);
      setTimeoutId(id);
    }
  }
  function watchAppend() {
    if (
      sliderRef.current.scrollWidth -
        (sliderRef.current.scrollLeft +
          document.getElementById("modal").offsetWidth) <
      1000
    ) {
      setChildState(childstate.concat(childstate, children));
    }
  }

  let facts = [];

  if (project) {
    facts = [
      {
        name: "Bautyp",
        value: project.bautyp,
      },
      {
        name: "Bauaufgabe",
        value: project.bauaufgabe,
      },
      {
        name: "BGF",
        value: project.bgf,
      },
      {
        name: "Ausführung",
        value: project.ausfuehrung,
      },
      {
        name: "Leistung",
        value: project.leistung,
      },
      {
        name: "Architekt",
        value: project.architekt,
      },
    ];
  }

  return (
    <Modal
      one
      navigateRoute={navigateRoute ? navigateRoute : "/projects"}
      backButton={backButton}
    >
      <div
        onMouseOver={(e) => {
          toggleCursorBack(true);
          setCurrentScrolling(-1);
        }}
        onMouseOut={() => {
          toggleCursorBack(false);
          setCurrentScrolling(0);
        }}
        role="button"
        className={styles.TriggerLeft}
        ref={leftRef}
        style={{
          position: "absolute",
          width: 200,
          height: "100%",
          zIndex: "10000",
        }}
      />
      <div
        onMouseOver={(e) => {
          toggleCursorForward(true);
          setCurrentScrolling(1);
        }}
        onMouseOut={() => {
          toggleCursorForward(false);
          setCurrentScrolling(0);
        }}
        role="spinbutton"
        className={styles.TriggerRight}
        ref={rightRef}
        style={{
          position: "absolute",
          right: 0,
          width: 200,
          height: "100%",
          zIndex: "10000",
        }}
      />
      <div
        ref={sliderRef}
        onMouseDown={dragStart}
        className={styles.Slider}
        onTouchMove={() => {
          watchAppend();
        }}
      >
        {project ? (
          <div
            className={styles.Facts}
            style={{ color: project.color ? project.color : "black" }}
          >
            <h1 className={styles.PHeader}>{project.name}</h1>
            <div className={styles.PDescription}>{project.description}</div>
            <table>
              {facts.map((f) => (
                <tr className={styles.TRow}>
                  <td className={styles.TName}>{f.name}</td>
                  <td className={styles.TValue}>{f.value}</td>
                </tr>
              ))}
            </table>
          </div>
        ) : null}
        {childstate}
      </div>
    </Modal>
  );
};

export default GalleryModal;
