import React from "react";
import Layout from "../../components/layout";
import * as styles from "./projects.module.scss";
import ProjectEntry from "../../components/projects/ProjectEntry";
import { graphql } from "gatsby";
import GalleryModal from "../../components/GalleryModal";
import Seo from "../../components/seo";

export const query = graphql`
  query ProjectsQuery {
    allSanityProject(sort: { fields: orderRank }) {
      edges {
        node {
          id
          _type
          name
          color
          architekt
          ausfuehrung
          bauaufgabe
          bautyp
          bgf
          description
          leistung
          pictures {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              _id
              id
              url
            }
          }
        }
      }
    }
  }
`;

const ProjectsPage = ({ data }) => {
  return (
    <GalleryModal navigateRoute="/">
      <Seo title="Projekte" />
      {data.allSanityProject.edges.map((edge) => (
        <>
          <ProjectEntry project={edge.node} />
        </>
      ))}
    </GalleryModal>
  );
};

ProjectsPage.Layout = Layout;

export default ProjectsPage;
