import { navigate } from "gatsby-link";
import React from "react";
import * as styles from "./ProjectEntry.module.scss";
import { CursorContext } from "../../context/CursorContext";
import SanityImage from "gatsby-plugin-sanity-image";
import slugify from "../../utils/slugify";

export default function ProjectEntry({ project, onMouseEnter, children }) {
  const [, setCursor] = React.useContext(CursorContext);

  const toggleCursor = React.useCallback((enabled) => {
    setCursor(() => ({ hover: enabled }));
  });

  return (
    <div
      className={styles.ProjectEntry}
      onClick={() => navigate(`/projects/${slugify(project.name)}`)}
      onMouseEnter={() => toggleCursor(true)}
      onMouseLeave={() => toggleCursor(false)}
    >
      <div className={styles.ProjectEntryContainer}>
        <div
          className={styles.ProjectEntryDetailsContainer}
          style={{
            color: project.color ? project.color : "black",
          }}
        >
          <h1 className="normalsize">{project.name}</h1>
        </div>
      </div>
      {project.pictures[0] ? (
        <SanityImage
          asset={project.pictures[0].asset}
          {...project.pictures[0].asset}
          className={styles.ModalThumb}
          alt={project.name}
        ></SanityImage>
      ) : (
        <div className={styles.ModalThumbPlaceholder}></div>
      )}
    </div>
  );
}
